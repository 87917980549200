.chart-tooltip {
  border-radius: 6px;
  padding: 10px;
  background-color: #1e2d3f;
  opacity: 0.9;
  color: $white !important;
  font-family: $font-family-base !important;
  font-size: 12px;
  font-weight: 500;

  .chart-item-container {
    padding: 4px;
  }

  .chart-label {
    text-align: center;
    border-bottom: 1px solid $white;
    padding-bottom: 5px;
  }

  .chart-item {
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
  }

  .pill {
    vertical-align: middle;
    display: inline-block;
    height: 14px;
    width: 14px;
    border-radius: 22px;
    border: 1px solid white;
  }
}

.recharts-wrapper {
  font-family: $font-family-base !important;

  .recharts-legend-wrapper {
    bottom: 0px !important;
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
      text-align: center;

      li {
        display: inline-block;
        margin-right: 10px;

        svg {
          display: inline-block;
          margin-right: 4px;
        }
      }
    }

    .recharts-legend-item-text {
      vertical-align: bottom;
    }
  }

  .recharts-default-legend {
    li:hover {
      cursor: pointer !important;
    }
  }

  .recharts-legend-item-text {
    font-weight: 500 !important;
    font-size: 14px !important;

    @include media-breakpoint-down(lg) {
      font-size: 12px !important;
    }
    @include media-breakpoint-down(md) {
      font-size: 12px !important;
    }
    @include media-breakpoint-down(sm) {
      font-size: 8px !important;
    }
  }

  .recharts-cartesian-axis-tick,
  .recharts-cartesian-axis-tick-value {
    font-weight: bold !important;
    font-size: 13px !important;
    fill: #081644 !important;

    @include media-breakpoint-down(lg) {
      font-size: 12px !important;
    }
    @include media-breakpoint-down(md) {
      font-size: 10px !important;
    }
    @include media-breakpoint-down(sm) {
      font-size: 6px !important;
    }
  }
}
