.drop-down {
  position: relative;

  .dialog {
    position: absolute;
    left: 0;
    right: auto;
    top: 100%;
    z-index: 2000;
    margin-top: 0.625rem;
    width: 16rem;
    background: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.11), 0 2px 10px rgba(0, 0, 0, 0.11),
      0 4px 12px rgba(0, 0, 0, 0.11);
    border: 0.5px solid rgba(115, 115, 117, 0.28) !important;
    border-radius: 0.25rem;
    display: flex;

    &.right {
      right: 0;
      left: auto;
    }

    &.left {
      left: 0;
      right: auto;
    }

    &.large {
      width: 20rem !important;
    }

    &.scrollbar {
      overflow-y: auto;
      max-height: 68vh;
    }

    .container {
      padding: 0;
      width: 100%;
    }

    .heading {
      padding: 0.625rem 0.625rem 0 1.25rem;
      font-size: 1.375rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      min-width: 100%;

      li {
        width: 100%;
        cursor: pointer;
        &:hover {
          background-color: $gray-100;
          border-radius: 1rem !important;
        }
      }
    }

    button {
      background: none;
      padding: 0.75rem 1.25rem;
      border: none;
      font-size: 0.875rem;
      font-weight: 500;
      width: 100%;
      text-align: left;
      color: $squid-ink;
    }

    .checkbox-container {
      padding: 0.75rem 1.25rem;
      font-size: 0.875rem;
      font-weight: 500;
      text-align: left;
      color: $squid-ink;
    }
  }
}
