.radio {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip-path: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  + span {
    padding-left: 2.375rem;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 1.75rem;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
      border: 1px solid $gray-300;
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  &:checked + span {
    &::before {
      box-shadow: inset 0 0 0 4px $white;
      background: $primary;
    }
  }
}

.radio-field {
  margin-bottom: 0 !important;
  cursor: pointer;

  &:hover .radio:not(:checked) + span::before {
    background: $gray-200;
    border: 1px solid $gray-500;
  }
}
