.applied-filters {
  padding-top: 0.5rem;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;

  .filter-summary {
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;

    .name {
      font-size: 0.75rem;
    }

    .item {
      position: relative;
      cursor: pointer;
      display: inline;
      background-color: $white;
      border: 1px solid $gray-300;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      font-size: 0.75rem;
      font-weight: 500;
      border-radius: 1.5rem;
      padding: 0.125rem 1.5rem 0.125rem 0.5rem;

      &:hover {
        border-color: $squid-ink;
      }

      &:active {
        background: $squid-ink-light-1;
      }

      .clear {
        color: $orange-red-crayola;
        bottom: 0.2rem;
        right: 0.4rem;
        position: absolute;
        transform: scale(0.9);
      }
    }
  }
}

.filter-bar {
  position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  padding: 1rem 0 !important;
  border-bottom: 0.5px solid $gray-300 !important;
}
