input[type="checkbox"] {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip-path: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;

  + span {
    padding-left: 2.375rem;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 1.75rem;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
    }

    &::before {
      left: 0;
      top: 0;
      border-radius: 0.25rem;
      border: 1px solid $gray-300;
      width: 1.75rem;
      height: 1.75rem;
      background: $white;
    }
  }

  &:checked + span {
    &::before {
      border-color: $primary;
      background-color: $primary;
    }

    &::after {
      top: 0.5rem;
      left: 0.688rem;
      width: 0.313rem;
      height: 0.75rem;
      border-right: 0.125rem solid $white;
      border-bottom: 0.125rem solid $white;
      transform: rotate(30deg);
    }
  }
}

input[type="checkbox"][disabled] + span {
  opacity: 0.4;

  &::before {
    background: $gray-400;
    border-color: $gray-400;
  }
}

.checkbox-field {
  margin-bottom: 0 !important;
  cursor: pointer;

  &:hover input[type="checkbox"]:not(:checked) + span::before {
    background: $gray-200;
    border: 1px solid $gray-500;
  }
}
