.slider {
  align-items: center;
  display: flex;
  position: relative;

  .left-arrow {
    position: absolute;
    left: 0px;
    margin-right: 5px;
    cursor: pointer;
    z-index: 10;
  }

  .right-arrow {
    position: absolute;
    right: 132px;
    cursor: pointer;
    z-index: 10;

    &.arrow-margin {
      right: 0 !important;
    }
  }

  .item-container {
    display: inline-flex;
    justify-content: flex-start;
    overflow-x: scroll;

    &.left-margin {
      margin-left: 12px !important;
    }

    &.right-margin {
      margin-right: 12px !important;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
