@import "../common/stylesheets/animate";
@import "../common/stylesheets/variables";

@import "~bootstrap/scss/bootstrap.scss";

@import "../common/stylesheets/checkbox";
@import "../common/stylesheets/radio";
@import "../common/stylesheets/select";
@import "../../components/filters/filter.scss";
@import "../../components/search/search.scss";
@import "../../components/forecast/forecastTable.scss";
@import "../../components/forecast/forecastChart.scss";
@import "../../components/common/dropdown/dropdown.scss";
@import "../../components/common/modal/modal.scss";
@import "../../components/project/project.scss";
@import "../common/stylesheets/switch";
@import "../common/stylesheets/datepicker";
@import "../common/slider/slider.scss";

*,
html {
  scroll-behavior: smooth !important;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
}

hr {
  border: 1px solid $gray-200 !important;
}

.dropdown-toggle::after {
  display: none;
}

.nav-link {
  font-weight: 600 !important;
  color: $white;
}

.navbar-brand {
  margin-bottom: 0;
}

.navbar-nav {
  .dropdown-menu {
    border-radius: 1rem !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.11), 0 2px 10px rgba(0, 0, 0, 0.11),
      0 4px 12px rgba(0, 0, 0, 0.11);
  }
}

.dropdown-item {
  &.active,
  &:active {
    color: $primary !important;
    font-weight: 700 !important;
    text-decoration: none;
    background-color: transparent !important;
  }

  &:hover,
  &:focus {
    border-radius: 0.3rem !important;
    background-color: $gray-200 !important;
  }
}

.footer {
  height: 60px;
  background-color: $gray-200;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.font-weight-medium {
  font-weight: 600 !important;
}

.small-heading {
  font-weight: 600 !important;
  font-size: 1rem;
}

.flex {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  &.flex-end {
    justify-content: flex-end;
  }
  &.middle {
    justify-content: center;
  }
}

.form-group,
.form-label,
.list-view-card {
  label {
    font-weight: 500;
    font-size: 0.875rem;
    color: $gray-500;

    .primary {
      color: $primary;
      font-weight: 500;
    }
  }
}

.label {
  font-weight: 500;
  font-size: 0.875rem;
  color: $gray-500;

  &.primary {
    font-size: 1rem;
  }
}

.form-control {
  background-color: $light;
  border: 1px solid $light;

  &:focus {
    box-shadow: none !important;
    border: 1px solid $primary !important;
  }

  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    opacity: 1;
    border: none;
  }
}

.circle {
  padding: 0 !important;
  width: 42px;
  height: 42px;
  background-color: $light;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;

  &.circle-small {
    width: 32px !important;
    height: 32px !important;
  }
}

.initials {
  position: relative;
  top: 4px; /* 25% of parent */
  font-size: 22px; /* 50% of parent */
  line-height: 22px; /* 50% of parent */
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
}

.charts {
  height: 300px !important;
}

.notification {
  position: relative;

  .dot-badge {
    border-radius: 50%;
    height: 0.6rem;
    width: 0.6rem;
    background: $orange-red-crayola;
    display: inline-block;
    top: 0.4rem;
    right: 0.4rem;
    position: absolute;
    margin: 0;
  }
}

.scrollbar,
body {
  overflow: auto;

  &.horizontal {
    float: left;
    overflow: auto;
    visibility: hidden;

    &.show {
      visibility: visible !important;
    }

    &:hover,
    &:focus {
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      border-radius: 0.313rem;
      background-color: $gray-300;
    }
  }

  .scrollbar-content {
    visibility: visible;

    &.fixed-height {
      max-height: 68vh;
    }
  }

  &::-webkit-scrollbar {
    width: 0.813rem;
    height: 0.813rem;
    background-color: $white;
  }

  &::-webkit-scrollbar-track {
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb:vertical {
    border-radius: 0.313rem;
    background-color: $gray-300;
  }
}

.button {
  display: inline-block;
  background: $white;
  color: $squid-ink;
  font-size: 0.875rem;
  font-weight: 500 !important;
  border: 1px solid $gray-300;
  border-radius: 1.5rem;
  padding: 0.5rem 1.25rem;
  cursor: pointer !important;
  text-decoration: none !important;
  text-align: center;
  text-wrap: nowrap;

  &.scale {
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      transform: scale(1.04);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.11), 0 2px 10px rgba(0, 0, 0, 0.11),
        0 4px 12px rgba(0, 0, 0, 0.11);
    }
  }

  &:hover:not([disabled]) {
    border-color: $squid-ink;
  }

  &:active {
    background: $squid-ink-light-1;
  }

  &[disabled] {
    opacity: 0.65;
    pointer-events: none;
  }

  &.active {
    background: $squid-ink;
    color: $white;
    border-color: $squid-ink;

    &:hover {
      background: $squid-ink-dark-1;
      border-color: $squid-ink-dark-1;
    }
  }

  &.danger {
    border: 1px solid $orange-red-crayola-light-2;
    color: $danger;

    &:hover:not([disabled]) {
      border: 1px solid $danger;
      color: $danger;
    }

    &:active {
      background: $orange-red-crayola-light-1;
    }

    &.primary {
      background: $danger;
      color: $white;
      border: 1px solid $danger;

      &:hover {
        background: $orange-red-crayola-dark-1;
        border: 1px solid $orange-red-crayola-dark-2;
        color: $white;
      }

      &:active {
        background: $orange-red-crayola-dark-2;
        color: $white;
      }

      &.active {
        background: $orange-red-crayola-dark-2;
        color: $white;
      }
    }
  }

  &.warning {
    border: 1px solid $orange-web-light-2;
    color: $warning;

    &:hover:not([disabled]) {
      border: 1px solid $warning;
      color: $warning;
    }

    &:active {
      background: $orange-web-light-1;
    }
  }

  &.small {
    padding: 0.313rem 1rem !important;
  }

  &.extra-small {
    padding: 0.15rem 0.875rem !important;
  }

  &.primary {
    background: $squid-ink;
    color: $white;
    border: 1px solid $squid-ink;

    &:hover {
      background: $squid-ink-dark-1;
      border: 1px solid $squid-ink-dark-2;
    }

    &:active {
      background: $squid-ink-dark-2;
    }

    &.active {
      background: $squid-ink-dark-2;
    }
  }

  &.success {
    border: 1px solid $green-pantone;
    color: $green-pantone;

    &:hover:not([disabled]) {
      border: 1px solid $green-pantone-dark-2;
    }

    &:active {
      background: $green-pantone-light-1;
    }

    &.primary {
      background: $green-pantone;
      color: $white;
      border: 1px solid $green-pantone;

      &:hover {
        background: $green-pantone-dark-1;
        border: 1px solid $green-pantone-dark-2;
      }

      &:active {
        background: $green-pantone-dark-2;
      }

      &.active {
        background: $green-pantone-dark-2;
      }
    }
  }

  &.hidden {
    visibility: hidden !important;
  }
}

.link {
  line-height: 1.2;
  font-weight: 400 !important;
  font-size: 1rem;
  color: $squid-ink;
  display: flex;
  background: transparent;
  border: none;
  text-decoration: none;
  align-items: center !important;

  &.small {
    font-size: 0.875rem !important;
    line-height: normal;
  }

  &.clear {
    color: $orange-red-crayola;
    display: flex;
    align-items: baseline;
  }

  &.light-bold {
    font-weight: 500 !important;
  }

  &.bold {
    font-weight: 600 !important;
  }

  &[disabled] {
    cursor: default;
    color: $gray-500;
  }

  &.underline {
    text-decoration: underline;
  }
}

.spinner-grow {
  width: 0.625rem !important;
  height: 0.625rem !important;
  animation-duration: 0.8s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
  animation-fill-mode: both !important;
  vertical-align: middle !important;

  &.spinner-grow-1 {
    animation-delay: -0.3s !important;
  }
  &.spinner-grow-2 {
    animation-delay: -0.15s !important;
  }
}

.rounded-extra-lg {
  border-radius: 1rem !important;
}

.border-radius {
  border-radius: 1.5rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow-x: auto;
  width: 100%;
}

.table-lg {
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-lg $table-cell-padding-x-lg;
  }
}

.table-sticky-header {
  th {
    position: sticky;
    top: 0;
  }

  table {
    max-height: 500px;
  }
}

.table-collapse {
  border-collapse: separate;
  border-spacing: 0;
}

.table-header-border {
  thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #b0bac5;
    border-top: 1px solid #b0bac5;

    &.small-width {
      width: 15%;
    }
  }

  &.border-top-none {
    thead th {
      border-top: none !important;
    }
  }
}

.table-striped-custom > tbody > tr:nth-of-type(odd) > * {
  background-color: $gray-100;
}

.arrow-icon {
  &.collapsed {
    transform: rotate(0deg);
    transition: transform 180ms ease-out;
  }
  &.expanded {
    transform: rotate(180deg);
    transition: transform 180ms ease-out;
  }
  &.back {
    svg {
      display: block;
      width: 14px;
      height: 14px;
    }
    transform: rotate(90deg);
    transition: transform 180ms ease-out;
    display: block;
  }
  &.forward {
    svg {
      display: block;
      width: 14px;
      height: 14px;
    }
    transform: rotate(270deg);
    transition: transform 180ms ease-out;
    display: block;
  }
}

.disabled {
  pointer-events: none;
  cursor: default !important;
  opacity: 0.8;
}

.highlighted,
.highlighted > div > input {
  background-color: $gray-200 !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .input-focus {
    box-shadow: none !important;
    border: 1px solid $primary !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.warning {
  svg {
    vertical-align: text-top !important;
  }
}

.layered-shadow {
  box-shadow: 0.3px 0.5px 0.5px rgba($black, 0.075),
    0.5px 1.1px 1.1px -1.4px rgba($black, 0.075),
    1.7px 3.4px 3.3px -2.8px rgba($black, 0.08),
    4.8px 9.5px 9.3px -4.1px rgba($black, 0.09) !important;
}

.icon-card {
  display: flex;
  padding-left: 1rem;
  align-items: center;
  .icon-card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    padding: 1rem;
    background-color: $gray-100;
  }
}

.card {
  .icon {
    svg {
      width: 26px;
      height: 26px;
    }
  }
}

.text-grey-500 {
  color: $gray-500;
}

.text-grey-400 {
  color: $gray-400;
}

.svg-margin-n2 {
  svg {
    margin-top: -2px !important;
  }
}

.svg {
  &.small {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.medium {
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.large {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .col-xll-4 {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}

input[type="file"]::file-selector-button {
  display: inline-block;
  background: $white;
  color: $squid-ink;
  font-size: 0.875rem;
  font-weight: 500 !important;
  border: 1px solid $gray-300;
  border-radius: 1.5rem;
  padding: 0.5rem 1.25rem;
  cursor: pointer !important;
  text-decoration: none !important;
  text-align: center;
}

input[type="file"] {
  height: 100% !important;
}

.badge {
  vertical-align: top !important;
}

.search-view-container {
  display: flex;
  justify-content: space-between;

  .flex-2 {
    flex: 2;
  }

  label {
    margin-bottom: 0 !important;
  }

  .uom {
    display: flex;
    flex: 2;
    justify-content: flex-end !important;
  }

  .configuration {
    display: flex;
    flex: 2;
    justify-content: flex-start !important;
  }
}

.search-bar {
  display: flex;
  position: relative;

  .search-icon {
    position: absolute;
    top: 16%;
    left: 24px;
  }

  .search-input {
    padding-left: 48px;
  }
}

.list-card {
  &.list-view {
    cursor: pointer;
  }

  .name {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .description {
    margin: 0;
    font-size: 0.875rem;
  }

  &.values {
    list-style: none;
    margin: 0 0 0 -8px;
    padding: 4px;
    display: flex;
    flex-wrap: wrap;

    .value-container {
      width: 200px;
      margin: 0;
      text-align: left;
      padding: 4px;
    }

    .list {
      width: 100%;
    }

    .key {
      font-size: 0.875rem;
      color: $gray-500;
      font-weight: 500;
    }

    .value {
      font-size: 0.875rem;
      font-weight: normal;
    }

    li {
      flex: 0 0 calc(9% - 14px);
      width: calc(9% - 14px);
      margin: 0;
      text-align: left;
      padding: 4px;
      display: flex;

      @include media-breakpoint-down(sm) {
        flex-basis: calc(22% - 12px);
        width: calc(22% - 12px);
      }
    }
  }
}

.doc-container {
  padding: 0 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 0.2rem;
}

.badge {
  padding: 0.2rem 0.6rem 0.25rem 0.6rem !important;
}

.text-bg-success,
.text-bg-danger,
.text-bg-error {
  color: $white !important;
}

.form-group {
  margin-bottom: 1rem !important;
}

.forecast-container {
  @include media-breakpoint-up(sm) {
    min-height: 60vh !important;
  }
  @include media-breakpoint-up(md) {
    min-height: 92vh !important;
  }
  @include media-breakpoint-up(lg) {
    min-height: 98vh !important;
  }
}

.border-bottom {
  border-bottom: 1px solid $gray-300 !important;
}

.sticky-top {
  z-index: 5 !important;
}

.sticky-header {
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 2400;
  transform: translateY(-100%);
  transition: transform 0.2s ease 0s;
  background: $white;
  width: 100%;
  height: 74px;
  padding: 14px 32px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $gray-300;

  &.show {
    transform: translateY(0px);
  }

  .basic-details {
    flex: 0 1 auto;
    overflow: hidden;

    .name {
      font-weight: 600;
    }

    .info {
      font-weight: 500;
      font-size: 0.875rem;
      color: $gray-500;
    }
  }
}

.cell {
  &.xs {
    width: 8rem;
  }

  &.sm {
    width: 10rem;
  }

  &.md {
    width: 12rem;
  }

  &.lg {
    width: 16rem;
  }

  &.xl {
    width: 20rem;
  }

  &.xxl {
    width: 22rem;
  }
}

.options {
  li {
    &::marker {
      color: $danger;
    }

    .value {
      font-size: 1rem;
      font-weight: 600;
      color: $primary;
    }
  }
}
