.switch-bar {
  display: flex;
  justify-content: space-between;

  &.middle {
    justify-content: center;
  }

  label {
    margin-bottom: 0 !important;
  }
}

.switch-container {
  display: flex !important;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-bottom: auto;
}

.switch-fieldset {
  border: none;
  padding: 0;
  margin: 0;
  position: sticky;
  left: 0px;
}

.switch-legend {
  display: block;
  margin-bottom: 16px;
  font-size: 1rem;
  font-weight: 600;
  text-align: center !important;
}

.switch-group {
  display: flex;
  background: $gray-200;
  border-radius: 32px;
  &.margin {
    margin-bottom: 18px;
  }
}

.switch-wrap {
  & input[type="radio"] {
    position: fixed !important;
    opacity: 0 !important;
  }
}

.switch {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  padding: 0.313rem 1rem;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  text-align: center;
  font-size: 0.875rem;
  cursor: pointer;

  &.switch-small {
    padding: 4px !important;
    min-width: 54px !important;
  }

  &:hover {
    background: $gray-300;
  }
}

input[type="radio"]:checked ~ .switch {
  background: $white;
  border: $white 2px solid !important;
  font-weight: 600 !important;
  position: relative;
  box-shadow: 0 1px 4px rgba(8, 22, 68, 0.12), 0 2px 6px rgba(8, 22, 68, 0.12),
    0 4px 8px rgba(8, 22, 68, 0.12);
}
