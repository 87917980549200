.modal-dialog-container {
  position: fixed;
  z-index: 150;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(30, 45, 63, 0.4);

  .modal-dialog-content {
    background-color: $white;
    margin: auto;
    padding: 25px;
    width: 30%;
    max-height: 72vh;

    &.medium {
      width: 50% !important;
    }

    &.large {
      width: 90% !important;
    }

    @include media-breakpoint-up(lg) {
      width: 400px;
    }

    @include media-breakpoint-down(md) {
      width: 60%;
    }
  }

  .modal-dialog-footer {
    flex: 20%;
    display: flex;
  }

  .icon {
    svg {
      width: 30px;
      height: 30px;
    }

    &.help {
      display: flex;
      justify-content: space-between;
    }
  }

  .header {
    font-size: 1.25rem;
    font-weight: 500;
    padding: 5px 0;
    vertical-align: middle;
  }

  .sub-header {
    font-weight: 500;
    font-size: 0.875rem;
    color: $gray-500;
  }
}
