.forecast {
  &.table {
    width: 100%;
    th {
      &.name {
        img {
          padding-right: 3px;
        }
      }
    }

    tbody td.editable {
      padding: 0.25rem !important;
    }

    &.itemarea {
      th:first-child {
        position: static;
      }
    }
  }

  th:first-child {
    position: sticky;
    left: 0px;
  }

  tbody tr:nth-of-type(odd) > th:first-child {
    background-color: $gray-100;
  }

  tbody tr:nth-of-type(even) > th:first-child {
    background-color: $white;
  }

  thead tr > th:first-child {
    background-color: $white;
  }

  .actions {
    min-width: 116px;

    &.small {
      min-width: 42px;
    }
  }
}

.input-focus {
  margin-top: 1px;
  box-shadow: none !important;
  border: 1px solid #b0bac5 !important;
  &.danger {
    border: 1px solid $danger !important;
  }
}
