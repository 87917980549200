.filter-dialog {
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
  margin-top: 1rem;
  width: 100%;
  background: $white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.11), 0 2px 10px rgba(0, 0, 0, 0.11),
    0 4px 12px rgba(0, 0, 0, 0.11);
  border: 0.5px solid rgba(115, 115, 117, 0.28) !important;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  max-height: 72vh;

  &.sidebar {
    position: static !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    width: 100% !important;
    left: 0 !important;
    border-top: 0.5px solid $gray-300 !important;
    border-bottom: 0 !important;
  }

  @include media-breakpoint-down(lg) {
    width: 98%;
  }

  @include media-breakpoint-up(lg) {
    max-height: 68vh;
  }

  @include media-breakpoint-down(sm) {
    width: 97%;
  }

  .search {
    padding: 0.25rem;
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.filter-fields {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.625rem;
}

.checkbox-fields {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  min-width: 100%;
}

label {
  display: block;
}

.filter-value-container {
  background: $white;

  display: block;
  min-height: 1.75rem;
  position: relative;
  margin: 0 0.5rem 0.175rem;
  width: 17rem;
  float: left;
  user-select: none;
  padding: 0.5rem 0.5rem 0.5rem 3.125rem;

  label {
    margin-bottom: 0 !important;
    cursor: pointer;
  }

  .checkbox-container {
    position: absolute;
    display: block;
    top: 0.875rem;
    left: 0.625rem;
    width: 1.75rem;
    height: 1.75rem;
  }

  .filter-value {
    font-size: 0.875rem !important;
    margin-bottom: 0;
    line-height: 1.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 500;
  }

  .filter-description {
    width: 100%;
    color: $gray-500;
    font-size: 0.875rem !important;
  }
}

.filter-actions {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
}

.offcanvas-search-backdrop {
  top: auto !important;
  z-index: 10 !important;
}

.tab {
  background: transparent;
  border: none;
  padding: 0.75rem 1rem;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  font-weight: 500;
}

.tab-seperator {
  border-bottom: 0.5px solid $gray-300 !important;
}
