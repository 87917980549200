$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
  xll: 1400px,
) !default;

$brookes-blue: #00427c;
$brookes-blue-light: #ebf5ff;

$squid-ink: #081644;
$squid-ink-dark-1: #061237;
$squid-ink-dark-2: #040c25;
$squid-ink-light-1: #edf1fd;
$squid-ink-light-2: #dae2fb;
$squid-ink-light-3: #c8d4f9;
$orange-red-crayola-dark-1: #ff5747;
$orange-red-crayola-dark-2: #ff4433;
$orange-red-crayola-light-1: #ffeceb;
$orange-red-crayola-light-2: #ffa299;
$orange-web-light-1: #fff7eb;
$orange-web-light-2: #fdc05d;

$azure: #007cf4;
$azure-light: #ebf5ff;

$charcoal: #283647;
$charcoal-light: #f2f5f8;

$apple-green: #8fb339;
$green-pantone: #00b050;
$green-pantone-dark-1: #00a349;
$green-pantone-dark-2: #008f40;
$green-pantone-light-1: #d6ffe9;
$green-pantone-light-2: #99ffc7;
$orange-web: #fca311;
$orange-red-crayola: #ff6253;
$columbia-blue: #afd9ff;
$gray-web: #7f7f7f;
$viridian-green: #009999;

$cultured: #eaecef;
$cadet-blue-crayola: #b0bac5;
$white: #fff;
$silver: #c3c2c1;
$silver-chalice: #afadac;

$gray-100: #f4f7fa;
$gray-200: #dfe6ee;
$gray-300: #b0bac5;
$gray-400: #8091a5;
$gray-500: #667587;
$gray-600: #3f4a5b;
$gray-700: #283647;
$gray-800: #243244;
$gray-900: #1e2d3f;

$primary: $squid-ink;
$secondary: $azure;
$success: $green-pantone;
$info: $columbia-blue;
$warning: $orange-web;
$danger: $orange-red-crayola;
$dark: $squid-ink;
$code-color: $danger;
$light: $gray-100;

$body-color: $squid-ink;
$black: $gray-900;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "error": $danger,
);

$font-family-base: -apple-system, BlinkMacSystemFont, "DM Sans", sans-serif,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans",
  sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
  "Noto Color Emoji" !default;

$table-hover-bg: $gray-200;
$table-striped-bg: $gray-100;
$table-cell-padding-y-lg: 0.75rem;
$table-cell-padding-x-lg: 0.75rem;
$alert-bg-level: -4 !default;
$alert-border-level: -5 !default;
$alert-color-level: 10 !default;

$btn-border-radius: 1.5rem;
$btn-border-radius-sm: 1.5rem;
$btn-border-radius-lg: 1.5rem;
$btn-padding-x: 1.25rem;
$btn-padding-x-sm: 1rem;
$btn-padding-x-lg: 1.25rem;
$btn-active-box-shadow: none;
$enable-negative-margins: true;
$navbar-padding-x: 1rem;
$badge-color: $white;
$tooltip-bg: $primary;
