.time-horizon {
  font-weight: 500;

  &.table {
    tbody {
      td {
        padding: 0.375rem 0.25rem 0 0 !important;
        vertical-align: middle;
      }
    }
  }
  &.table-hover tbody tr:hover {
    background-color: $gray-100;
  }
}

.action-container {
  width: 200px;
}

.new-icon {
  svg {
    margin: 0 4px 3px 0;
    width: 14px;
    height: 14px;
  }
}

.project-search {
  .filter-bar {
    border-bottom: none !important;
  }
}

.project-search-content {
  height: 60vh;
}
